import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import { navLinks } from "../../config"

const StyledBackdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  display: ${({ open }) => (open ? "block" : "none")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledContainer = styled.div`
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  outline: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: none;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
  height: 100vh;
  width: 100%;
  text-align: left;
  padding: 2rem;
  position: relative;
  right: 0;
  margin-left: auto;
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  .nav-link {
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    padding: 1.5rem 0;
  }
`

const Sidebar = ({ open, setOpen }) => {
  const { menu } = navLinks

  const { backnumbers } = useStaticQuery(
    graphql`
      {
        backnumbers: allMdx(
          filter: { fileAbsolutePath: { regex: "/backnumbers/" } }
          sort: { fields: [frontmatter___file], order: DESC }
        ) {
          edges {
            node {
              body
              frontmatter {
                title
                date
                file
              }
            }
          }
        }
      }
    `
  )

  const latestIssue = backnumbers.edges[0].node.frontmatter.file

  return (
    <>
      <StyledContainer open={open} aria-hidden={!open} tabIndex={open ? 1 : -1}>
        <StyledNav>
          {menu.map(({ name, url }, key) => (
            <Link
              className="nav-link"
              key={key}
              to={url}
              onClick={() => setOpen(!open)}
            >
              {name}
            </Link>
          ))}
          <a
            className="nav-link"
            href={`/${latestIssue}`}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => setOpen(!open)}
          >
            最新号を読む
          </a>
        </StyledNav>
      </StyledContainer>
      <StyledBackdrop open={open} />
    </>
  )
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default Sidebar
