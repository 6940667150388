module.exports = {
  //-- SITE SETTINGS -----
  author: "Rintaro Okamura",
  siteTitle: "東京25ジャーナル",
  siteShortTitle: "東京25ジャーナル", // Used as logo text in header, footer, and splash screen
  siteLogo: "logo.svg",
  siteDescription: "東京25区管内の政治・行政、経済、社会、トピックス",
  siteUrl: "https://tokyo.25journal.net",
  siteLanguage: "ja_JP",
  siteIcon: "content/favicon.png", // Relative to gatsby-config file
  seoTitleSuffix: "東京25ジャーナル", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "G-Z8YV3M1D1T", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      primary: "#212121",
      secondary: "#FFF4D9",
      tertiary: "#F2F2F2",
      text: "#000000",
      subtext: "#555555",
      background: "#FFFFFF",
      card: "#FFFFFF",
      scrollBar: "rgba(0, 0, 0, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      primary: "#FAFAFA",
      secondary: "#2A2926",
      tertiary: "#252525",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      background: "#121212",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadow: "rgba(0, 0, 0, 0.16)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "Roboto, Arial, sans-serif",
  },

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "東京25ジャーナルについて",
        url: "/#about",
      },
      {
        name: "バックナンバー",
        url: "/#backnumbers",
      },
      {
        name: "コンタクト",
        url: "/#contact",
      },
    ],
  },
  footerLinks: [
    {
      name: "バックナンバー",
      url: "/backnumbers",
    },
    {
      name: "FAQ",
      url: "/faq",
    },
    {
      name: "発行協力金会員について",
      url: "/supporter",
    },
  ],
}
