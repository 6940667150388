import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"

import { navLinks } from "../../config"

const StyledNav = styled.nav`
  display: none;
  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 35.25rem;
    background: ${({ theme }) => theme.colors.background};
    a {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
  .nav-link {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
    position: relative;
    padding: 0;
    &::before {
      transition: 200ms ease-out;
      height: 0.1563rem;
      content: "";
      position: absolute;
      background-color: ${({ theme }) => theme.colors.primary};
      width: 0%;
      bottom: -0.125rem;
    }
    &:hover::before {
      width: 100%;
    }
  }
  }
`

const Navbar = () => {
  const { menu } = navLinks

  const { backnumbers } = useStaticQuery(
    graphql`
      {
        backnumbers: allMdx(
          filter: { fileAbsolutePath: { regex: "/backnumbers/" } }
          sort: { fields: [frontmatter___file], order: DESC }
        ) {
          edges {
            node {
              body
              frontmatter {
                title
                date
                file
              }
            }
          }
        }
      }
    `
  )

  const latestIssue = backnumbers.edges[0].node.frontmatter.file

  return (
    <StyledNav>
      {menu.map(({ name, url }, key) => {
        return (
          <Link className="nav-link" key={key} to={url}>
            {name}
          </Link>
        )
      })}
      <a
        className="nav-link"
        href={`/${latestIssue}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        最新号を読む
      </a>
    </StyledNav>
  )
}

export default Navbar
